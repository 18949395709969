import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tasking-requests"
    }}>{`Tasking Requests`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`If suitable data over your area of interest doesn't exist in the archive, you can submit a request for new data to be collected by Capella's SAR satellite constellation.`}</p>
    <p>{`To submit a new request, you will need to provide information on:`}</p>
    <ul>
      <li parentName="ul">{`where you would like the data to be collected`}</li>
      <li parentName="ul">{`when you would like the data to be collected`}</li>
      <li parentName="ul">{`what collection type you would like to use`}</li>
      <li parentName="ul">{`the frequency of the tasking request`}</li>
      <li parentName="ul">{`a tasking tier to identify the importance of the tasking request for the scheduling system.`}</li>
    </ul>
    <p>{`Once a tasking request has been submitted, Capella's tasking system will automatically determine whether it is possible to fulfill your tasking request. When a tasking request is accepted, the system will provide information on the status of the request. More information on the types of tasking available from Capella and how requests are scheduled is available on the `}<a parentName="p" {...{
        "href": "https://support.capellaspace.com/hc/en-us/categories/360002628311-Tasking"
      }}>{`Capella Support site`}</a>{`.`}</p>
    <h2 {...{
      "id": "collection-tiers"
    }}>{`Collection Tiers`}</h2>
    <p>{`Capella Space’s automated tasking system for repeat and single point and area tasks leverages a number of tasking (collection) tiers to denote the importance of a tasking request for the Scheduler.`}</p>
    <h3 {...{
      "id": "single-and-area-tasking"
    }}>{`Single and Area Tasking`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Collection Tier`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`urgent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Designed for time-sensitive situations where rapid collection speed is mission critical. `}<br />{` Urgent tasks are considered for scheduling first. `}<br />{`Minimum acquisition window is 24 hours.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`priority`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optimal minimum acquisition window for situations when precise imaging geometries matter.`}<br />{`Priority tasks are considered for scheduling after Urgent tasks.`}<br />{`Minimum acquisition window is 72 hours.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`standard`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provides assured data collection upon acceptance.`}<br />{`Standard tasks are considered for scheduling after the Urgent and Priority tasks.`}<br />{`Minimum acquisition window is 7 days.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`flexible`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ideal for leveraging variations in capacity without the risk of interfering with tasks of higher importance.`}<br />{`Flexible tasks can be added, shuffled, and removed from the schedule to accommodate tasks with higher importance.`}<br />{`Customers set their own acquisition windows.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "repeat-tasking"
    }}>{`Repeat Tasking`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Collection Tier`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`routine`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Get coverage on a regular basis.`}<br />{`Offers premium importance for data collection when regularity and consistent imaging geometry are crucial.`}<br />{`Customers set their own acquisition windows. Minimum repeat cycle is 24 hours.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`flexible`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ideal for leveraging variations in capacity without the risk of interfering with tasks of higher importance.`}<br />{`Flexible tasks can be added, shuffled, and removed from the schedule to accommodate tasks with higher importance.`}<br />{`Customers set their own acquisition windows. Minimum repeat cycle is 24 hours.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`For more information on Capella's tasking tiers and their role in scheduling and prioritization, see the Support articles for `}<a parentName="p" {...{
        "href": "https://support.capellaspace.com/hc/en-us/articles/360059287311-What-are-Capella-s-Tasking-Tiers"
      }}>{`What are Capella's Tasking Tiers?`}</a>{`, `}<a parentName="p" {...{
        "href": "https://support.capellaspace.com/hc/en-us/articles/360058857812-What-is-Capella-s-No-Bumping-policy"
      }}>{`What is Capella's "No Bumping" Policy?`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://support.capellaspace.com/hc/en-us/articles/18138985656724-Scheduling-of-Tasking-Requests"
      }}>{`Scheduling of Tasking Requests`}</a>{`.`}</p>
    <h2 {...{
      "id": "collection-types"
    }}>{`Collection Types`}</h2>
    <p>{`When submitting a tasking request, you will need to include the desired collection type. The collection type sets the collect mode, number of looks, and resolutions for the resulting imagery. The available collection types can be found by submitting:`}</p>
    <pre><code parentName="pre" {...{}}>{`    GET https://api.capellaspace.com/collectiontypes
`}</code></pre>
    <p>{`This will return the name of all the available collection types along with properties about those collection types. `}</p>
    <h2 {...{
      "id": "submitting-a-tasking-request"
    }}>{`Submitting a Tasking Request`}</h2>
    <h3 {...{
      "id": "point-tasking-request"
    }}>{`Point Tasking Request`}</h3>
    <p>{`To submit a single tasking request using a point Area of Interest, you will make a request to the `}<inlineCode parentName="p">{`task`}</inlineCode>{` endpoint. The request body is a GeoJSON feature, which includes properties describing the parameters for the tasking request:`}</p>
    <pre><code parentName="pre" {...{}}>{`    POST https://api.capellaspace.com/task

    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -71.09727327650678,
          42.34643585900935
        ]
      },
      "properties": {
        "taskingrequestDescription": "My first tasking request",
        "taskingrequestName": "Tasking Test 1",
        "windowOpen": "2024-10-01T00:00:00.000Z",
        "windowClose": "2024-10-08T00:00:00.000Z",
        "collectionTier": "standard",
        "collectionType": "spotlight",
        "collectConstraints": {
          lookDirection": "right",
          "ascDesc": "ascending",
          "orbitalPlanes": [45, 53],
          "offNadirMin": 5,
          "offNadirMax": 50,
          "azimuthAngleMin": 340,
          "azimuthAngleMax": 20,
          "localTime": "night"
          },
        },
        "preApproval": true,
        “customAttribute1”: “My New Project”,
        "customAttribute2”: “XYZ123”
      }
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`geometry`}</inlineCode>{` can be either a point. If you would like to use a polygon, follow the Area Tasking Request example. The `}<inlineCode parentName="p">{`taskingrequestName`}</inlineCode>{` and `}<inlineCode parentName="p">{`taskingrequestDescription`}</inlineCode>{` can be used to help characterize and describe the tasking request. The remaining properties define how the data will be collected:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`windowOpen`}</inlineCode>{` is a required field that should be set at the earliest time (UTC) you would like data collected`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowClose`}</inlineCode>{` can optionally be set for a time longer than the `}<inlineCode parentName="li">{`collectionTier`}</inlineCode>{` indicates, which will extend the imaging window for your request`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`collectionTier`}</inlineCode>{` can be either `}<inlineCode parentName="li">{`urgent`}</inlineCode>{`, `}<inlineCode parentName="li">{`priority`}</inlineCode>{`, `}<inlineCode parentName="li">{`standard`}</inlineCode>{`, or `}<inlineCode parentName="li">{`flexible`}</inlineCode>{` and will indicate the importance of the tasking request for the scheduling system`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`collectionType`}</inlineCode>{` indicates the desired collection type`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`localTime`}</inlineCode>{` can be used to restrict the time of day at which the image is collected. This value is defined by either a pre-defined value or a list of time ranges, representing the seconds in the day when the collect can be taken. For more information, see the `}<a parentName="li" {...{
          "href": "https://docs.capellaspace.com/constellation-tasking/tasking-requests#designating-collection-time"
        }}>{`Designating Collection Time`}</a>{` section.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`preApproval`}</inlineCode>{` will skip the tasking request cost review step if set to `}<inlineCode parentName="li">{`true`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`customAttribute1`}</inlineCode>{` is an optional custom attribute to help you track a Capella task with your own metadata or internal systems `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`customAttribute2`}</inlineCode>{` is an optional custom attribute to help you track a Capella task with your own metadata or internal systems`}</li>
    </ul>
    <p>{`In addition, you can specify a number of `}<inlineCode parentName="p">{`collectConstraints`}</inlineCode>{` to set the imaging geometry for the collect. In the example above, the tasking request  nighttime with right looking, ascending, mid-inclination orbits, and a look angle between 5 degrees and 50 degrees. For any `}<inlineCode parentName="p">{`collectConstraints`}</inlineCode>{` parameter not specifically defined, the default will be used.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`lookDirection`}</inlineCode>{` indicates the view angle, which can be `}<inlineCode parentName="li">{`right`}</inlineCode>{`, `}<inlineCode parentName="li">{`left`}</inlineCode>{`, or `}<inlineCode parentName="li">{`either`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`ascDesc`}</inlineCode>{` indicates the orbit state, which can be `}<inlineCode parentName="li">{`ascending`}</inlineCode>{`, `}<inlineCode parentName="li">{`descending`}</inlineCode>{`, or `}<inlineCode parentName="li">{`either`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`orbitalPlanes`}</inlineCode>{` is an array of orbital plane values that can be used to service the request, which can be `}<inlineCode parentName="li">{`45`}</inlineCode>{`, `}<inlineCode parentName="li">{`53`}</inlineCode>{`, or `}<inlineCode parentName="li">{`97`}</inlineCode>{`. `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`offNadirMin`}</inlineCode>{` and `}<inlineCode parentName="li">{`offNadirMax`}</inlineCode>{` indicate the angles between the sub-satellite point and the collect center point from the satellite to the target. The values for these parameters are defined in degrees, and the minimum value must not exceed the maximum.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`azimuthAngleMin`}</inlineCode>{` and `}<inlineCode parentName="li">{`azimuthAngleMax`}</inlineCode>{` indicate the clockwise angle with respect to North in a topocentric geodetic ENZ coordinate system from the target to the satellite. The values for this parameter are defined in degrees and can be set as a range, wrap around (i.e. 340 to 20), or as the same value, if you have a specific viewing angle you would like to task for. `}</li>
    </ul>
    <p>{`There are many other `}<inlineCode parentName="p">{`collectConstraints`}</inlineCode>{` parameters that can defined, including the `}<inlineCode parentName="p">{`grazingAngleMin`}</inlineCode>{` and `}<inlineCode parentName="p">{`grazingAngleMax`}</inlineCode>{`. See the Support article for `}<a parentName="p" {...{
        "href": "https://support.capellaspace.com/hc/en-us/articles/360059226651-What-are-Capella-s-custom-parameters"
      }}>{`What are Capella's custom parameters?`}</a>{` for more details.`}</p>
    <p>{`The response will contain detailed information about your area tasking request, including additional information about the collect constraints set by the `}<inlineCode parentName="p">{`collectionType`}</inlineCode>{`. `}</p>
    <h3 {...{
      "id": "area-tasking-request"
    }}>{`Area Tasking Request`}</h3>
    <p>{`To submit an area tasking request using a polygon Area of Interest, you will make a request to the `}<inlineCode parentName="p">{`task`}</inlineCode>{` endpoint. The request body is a GeoJSON feature, which includes properties describing the parameters for the tasking request:`}</p>
    <pre><code parentName="pre" {...{}}>{`    POST https://api.capellaspace.com/task

    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              179.84893798828125,
              66.42553717157787
            ],
            [
              180.25543212890625,
              66.42553717157787
            ],
            [
              180.25543212890625,
              66.58430877083293
            ],
            [
              179.84893798828125,
              66.58430877083293
            ],
            [
              179.84893798828125,
              66.42553717157787
            ]
          ]
        ]
      },
      "properties": {
        "taskingrequestName": "Area Tasking Test 1",
        "taskingrequestDescription": "My first area tasking request",
        "windowOpen": "2022-11-18T00:00:00.000Z",
        "windowClose": "2022-12-29T00:00:00.000Z",
        "collectionTier": "standard",
        "collectionType": "stripmap_100",
        "preApproval": false,
        “customAttribute1”: “My New Project”,
        "customAttribute2”: “XYZ123”
      }
    }
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`geometry`}</inlineCode>{` can must be polygon. Multi-polygons and intersecting polygons are not accepted. The `}<inlineCode parentName="p">{`taskingrequestName`}</inlineCode>{` and `}<inlineCode parentName="p">{`taskingrequestDescription`}</inlineCode>{` can be used to help characterize and describe the tasking request. The remaining properties define how the data will be collected:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`windowOpen`}</inlineCode>{` is a required field that should be set at the earliest time (UTC) you would like data collected`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowClose`}</inlineCode>{` can optionally be set for a time longer than the `}<inlineCode parentName="li">{`collectionTier`}</inlineCode>{` indicates, which will extend the imaging window for your request`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`collectionTier`}</inlineCode>{` can be either `}<inlineCode parentName="li">{`urgent`}</inlineCode>{`, `}<inlineCode parentName="li">{`priority`}</inlineCode>{`, `}<inlineCode parentName="li">{`standard`}</inlineCode>{`, or `}<inlineCode parentName="li">{`flexible`}</inlineCode>{` and will indicate the importance of the tasking request for the scheduling system`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`collectionType`}</inlineCode>{` indicates the desired collection type. For area tasks, `}<inlineCode parentName="li">{`stripmap_100`}</inlineCode>{` is the sole `}<inlineCode parentName="li">{`collectionType`}</inlineCode>{` accepted as the system will optimize the length of images to cover the AOI.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`preApproval`}</inlineCode>{` will skip the tasking request cost review step if set to `}<inlineCode parentName="li">{`true`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`customAttribute1`}</inlineCode>{` is an optional custom attribute to help you track a Capella task with your own metadata or internal systems `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`customAttribute2`}</inlineCode>{` is an optional custom attribute to help you track a Capella task with your own metadata or internal systems`}</li>
    </ul>
    <p>{`The response will contain detailed information about your area tasking request, including additional information about the collect constraints set by the `}<inlineCode parentName="p">{`collectionType`}</inlineCode>{`. For more information on the collection tiers available for single and area tasking, see the `}<a parentName="p" {...{
        "href": "https://support.capellaspace.com/hc/en-us/articles/360059287311"
      }}>{`Capella Support site`}</a>{`.`}</p>
    <h3 {...{
      "id": "repeat-tasking-request"
    }}>{`Repeat Tasking Request`}</h3>
    <p>{`To submit a repeat tasking request, you will make a request to the repeat-requests endpoint. The request body is a GeoJSON feature, which includes properties describing the parameters for the repeat tasking request: `}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.capellaspace.com/repeat-requests

{ 
  "type": "Feature",
  "geometry":{
    "coordinates":[ 
      -118.22537899999998, 
      36.561082999999996 
    ], 
    "type":"Point" 
  }, 
  "properties":{ 
    "repeatRequestName":"Repeat Task Test 1", 
    "repeatRequestDescription":"My first repeat task request", 
    "repetitionProperties":{ 
      "repeatStart": "2024-10-01T01:02:20.093Z", 
      "repeatEnd":"2024-12-31T22:02:20.093Z",
      "repetitionInterval":3, 
      "maintainSceneFraming":true, 
      "lookAngleTolerance":10,
      "azimuthAngleTolerance": 10 
    }, 
    "collectionTier":"routine", 
    "collectionType": "spotlight_wide",
    "collectConstraints": {
      "orbitalPlanes": [97],
      "offNadirMin": 20,
      "offNadirMax": 40
    }
    "customAttribute1": "My New Project", 
    "customAttribute2": "XYZ123" 
  }
} 
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`geometry`}</inlineCode>{` for repeat tasking can only be a point target. Polygon, multi-polygons and intersecting polygons are not accepted. The `}<inlineCode parentName="p">{`repeatRequestName`}</inlineCode>{` and `}<inlineCode parentName="p">{`repeatRequestDescription`}</inlineCode>{` can be used to help characterize and describe the repeat tasking request. The remaining properties define how the data will be collected: `}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`repeatStart`}</inlineCode>{` is the earliest date and time in UTC you would like data collected `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`repetitionInterval`}</inlineCode>{` is the number of days between the start of derived requests. For example, a `}<inlineCode parentName="li">{`repetitionInterval`}</inlineCode>{` equal to `}<inlineCode parentName="li">{`5`}</inlineCode>{` means requests will open 5 days after the opening previous request`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`maintainSceneFraming`}</inlineCode>{` is a flag to indicate that consistent geometries (look direction, orbit state, orbital plane) should be maintained across all acquisitions. Setting this parameter to `}<inlineCode parentName="li">{`true`}</inlineCode>{` allows you to set the `}<inlineCode parentName="li">{`lookAngleTolerance`}</inlineCode>{` and `}<inlineCode parentName="li">{`azimuthAngleTolerance`}</inlineCode>{`. `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`lookAngleTolerance`}</inlineCode>{` is the tolerance range for look angles that should be maintained across all acquisitions, if `}<inlineCode parentName="li">{`maintainSceneFraming`}</inlineCode>{` is enabled `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`azimuthAngleTolerance`}</inlineCode>{` is the tolerance range for azimuth angles that should be maintained across all acquisitions, if `}<inlineCode parentName="li">{`maintainSceneFraming`}</inlineCode>{` is enabled `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`collectionTier`}</inlineCode>{` can be either `}<inlineCode parentName="li">{`routine`}</inlineCode>{` or `}<inlineCode parentName="li">{`flexible`}</inlineCode>{` and will indicate your preference for the collection importance for the scheduling system  `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`collectionType`}</inlineCode>{` indicates the desired collection type`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`customAttribute1`}</inlineCode>{` is an optional custom attribute to help you track a Capella task with your own metadata or internal systems  `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`customAttribute2`}</inlineCode>{` is an optional custom attribute to help you track a Capella task with your own metadata or internal systems  `}</li>
    </ul>
    <p>{`Repeat tasks have three available triggers to end the series: `}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`repetitionCount`}</inlineCode>{` is the total number of acquisitions for a repeat series. Once this number is met with completed acquisitions, the series will end. `}</li>
      <li parentName="ol"><inlineCode parentName="li">{`repetitionEnd`}</inlineCode>{` is the latest date and time in UTC you would like data collected `}</li>
      <li parentName="ol"><inlineCode parentName="li">{`repetitionInterval`}</inlineCode>{` alone without either of the other two parameters specifies means you would like data collected until you send a `}<a parentName="li" {...{
          "href": "https://docs.capellaspace.com/constellation-tasking/cancel-task#repeat-tasking-request"
        }}>{`PATCH`}</a>{` request to cancel the repeat series `}</li>
    </ol>
    <p>{`In addition, you can specify a number of `}<inlineCode parentName="p">{`collectConstraints`}</inlineCode>{` to set the imaging geometry for the collect. In the example above, the request is for a Spotlight Wide image taken anytime by a satellite in a 97 degree orbit with a look angle between 20 degrees and 40 degrees. Because the request specifies the `}<inlineCode parentName="p">{`maintainSceneFraming`}</inlineCode>{` as `}<inlineCode parentName="p">{`true`}</inlineCode>{`, all collects created by the repeat series will be collected with the same orbit state and look direction. This repeat series will generate a tasking request every three days from October 1, 2024 to December 31, 2024. There are many other `}<inlineCode parentName="p">{`collectConstraints`}</inlineCode>{` parameters that can defined, including the `}<inlineCode parentName="p">{`grazingAngleMin`}</inlineCode>{` and `}<inlineCode parentName="p">{`grazingAngleMax`}</inlineCode>{`. See the Support article for `}<a parentName="p" {...{
        "href": "https://support.capellaspace.com/hc/en-us/articles/360059226651-What-are-Capella-s-custom-parameters"
      }}>{`What are Capella's custom parameters?`}</a>{` for more details.The response will contain detailed information about your repeat tasking request, including the specific constraints used for imaging. For more information on the collection tiers available for repeat tasking, see the `}<a parentName="p" {...{
        "href": "https://support.capellaspace.com/hc/en-us/articles/4790778283028-Repeat-tasking-tiers"
      }}>{`Capella Support`}</a>{` site. `}</p>
    <h2 {...{
      "id": "submitting-an-existing-tasking-request-or-collect-for-retasking"
    }}>{`Submitting an Existing Tasking Request or Collect for Retasking`}</h2>
    <h3 {...{
      "id": "retasking-a-tasking-request-or-a-single-tasking-request-from-a-repeat-series"
    }}>{`Retasking a Tasking Request or a Single Tasking Request from a Repeat Series`}</h3>
    <p>{`If you have an existing tasking request or a single task from a repeat series that you would like Capella to recollect for you, you can submit the existing `}<inlineCode parentName="p">{`taskingRequestId`}</inlineCode>{` for retasking by making a request to the POST `}<inlineCode parentName="p">{`task/{taskingRequestId}/retask`}</inlineCode>{` endpoint. This request will generate a one-time tasking request with the point target from the original tasking request. This endpoint cannot be used to retask area (polygon) tasking requests. `}</p>
    <p>{`The request can be sent with an empty GeoJSON feature body or with a select number of properties to add identifying information to the request or change the Tasking Tier and collection time parameters of the request:`}</p>
    <pre><code parentName="pre" {...{}}>{`
  POST https://api.capellaspace.com/task/{taskingRequestID}/retask
    {
    "retaskRequestName": "My first retasking request",
    "retaskRequestDescription": "Retasking Test 1",
    "windowOpen": "2023-12-13T00:49:59.944Z",
    "windowClose": "2023-12-14T00:49:59.944Z",
    "collectionTier": "urgent",
    "archiveHoldback": "30_day"
    }
`}</code></pre>
    <p>{`All the available properties are optional. If not specified, the values will default to those from the original tasking request. The original `}<inlineCode parentName="p">{`taskingRequestId`}</inlineCode>{` will be appended to the `}<inlineCode parentName="p">{`retaskRequestDescription`}</inlineCode>{`, if specified, or will be appended to the `}<inlineCode parentName="p">{`taksingRequestDescription`}</inlineCode>{` from the original tasking request, if not specified.
For example, the `}<inlineCode parentName="p">{`Retasking Test 1`}</inlineCode>{` specified above will become `}<inlineCode parentName="p">{`Retasking Test 1 – TRID: taskingRequestId`}</inlineCode>{`. If the original tasking request had a `}<inlineCode parentName="p">{`taskingRequestDescription`}</inlineCode>{` of `}<inlineCode parentName="p">{`Nighttime collect`}</inlineCode>{` and a new description is not specified, the description of the new tasking request will become `}<inlineCode parentName="p">{`Nighttime collect – TRID: taskingRequestId`}</inlineCode>{`. This is to provide the user with an easy way to link between the original and new tasking requests. `}</p>
    <p>{`The other available parameters can be used to set the importance and timing of when the data should be collected:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`retaskRequestName`}</inlineCode>{` and `}<inlineCode parentName="li">{`retaskRequestDescription`}</inlineCode>{` can be used to help characterize and describe the new tasking request `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowOpen`}</inlineCode>{` can optionally be set to a specific time in UTC you would like data collection to start. If not specified, the `}<inlineCode parentName="li">{`windowOpen`}</inlineCode>{` will automatically default to the date and time of submission. `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowClose`}</inlineCode>{` can optionally be set for a time longer than the `}<inlineCode parentName="li">{`collectionTier`}</inlineCode>{` indicates, which will extend the acuqisiton window for your request`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`collectionTier`}</inlineCode>{` can be optionally set indicate the importance of the tasking request for the scheduling system and can be set to `}<inlineCode parentName="li">{`urgent`}</inlineCode>{`, `}<inlineCode parentName="li">{`priority`}</inlineCode>{`, `}<inlineCode parentName="li">{`standard`}</inlineCode>{`, or `}<inlineCode parentName="li">{`flexible`}</inlineCode>{`. If not specified, the `}<inlineCode parentName="li">{`collectionTier`}</inlineCode>{` will default to the tier used in the original request.   `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`archiveHoldback`}</inlineCode>{` sets the timeline for which the resulting collect is made available in the Capella archive and can be set to `}<inlineCode parentName="li">{`none`}</inlineCode>{`, `}<inlineCode parentName="li">{`30_day`}</inlineCode>{`, `}<inlineCode parentName="li">{`1_year`}</inlineCode>{`, and `}<inlineCode parentName="li">{`permanent`}</inlineCode>{`. If not specified, the `}<inlineCode parentName="li">{`archiveHoldback`}</inlineCode>{` will default to the value used in the original request.`}</li>
    </ul>
    <p>{`All other properties, including the `}<inlineCode parentName="p">{`collectConstraints`}</inlineCode>{`, for the retasking request are inherited from the original tasking request. The response will contain detailed information about your new single tasking request, including the specific constraints used for imaging. `}</p>
    <h3 {...{
      "id": "retasking-an-image-from-the-archive"
    }}>{`Retasking an Image from the Archive`}</h3>
    <p>{`If you have an existing collection or have `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/accessing-data/searching-for-data"
      }}>{`found an image from the Capella archive`}</a>{` that you will like Capella to recollect for you, you can submit the existing `}<inlineCode parentName="p">{`collectId`}</inlineCode>{` for retasking by making a request to the POST `}<inlineCode parentName="p">{`collects/{collectId}/retask`}</inlineCode>{` endpoint. This request will generate a one-time tasking request with a point target constructed from the center point of the original collection. This endpoint cannot be used to create a repeat tasking request. `}</p>
    <p>{`The request can be sent with an empty GeoJSON feature body or with a select number of properties to add identifying information to the request, change the Tasking Tier and collection time parameters, and modify the look angles of the request:`}</p>
    <pre><code parentName="pre" {...{}}>{`  POST https://api.capellaspace.com/collects/{collectID}/retask
    {
      "retaskRequestName": "My first recollect request",
      "retaskRequestDescription": "Collect Retasking Test 1",
      "windowOpen": "2023-07-20T00:49:59.944Z",
      "windowClose": "2023-07-21T00:49:59.944Z",
      "collectionTier": "priority",
      "offNadirMin": "15"
      "offNadirMax": "50"
      "archiveHoldback": "1_year"
    }
`}</code></pre>
    <p>{`All the available properties are optional. If not specified, the values will default to `}<inlineCode parentName="p">{`Retask Request`}</inlineCode>{` for the `}<inlineCode parentName="p">{`retaskRequestName`}</inlineCode>{`, `}<inlineCode parentName="p">{`standard`}</inlineCode>{` for the `}<inlineCode parentName="p">{`collectionTier`}</inlineCode>{`, the date and time of submission for the `}<inlineCode parentName="p">{`Window Open`}</inlineCode>{`, and the `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/constellation-tasking/tasking-requests#new-collection-tier-names"
      }}>{`minimum window duration of the `}<inlineCode parentName="a">{`standard`}</inlineCode>{` tier`}</a>{` for the `}<inlineCode parentName="p">{`windowClose`}</inlineCode>{`. The `}<inlineCode parentName="p">{`offNadirMin`}</inlineCode>{` and `}<inlineCode parentName="p">{`offNadirMax`}</inlineCode>{` will default to -/+ 5 degrees from the look angle of the original `}<inlineCode parentName="p">{`collectId`}</inlineCode>{`. Addtionally, the original `}<inlineCode parentName="p">{`collectId`}</inlineCode>{` will be appended to the `}<inlineCode parentName="p">{`retaskRequestDescription`}</inlineCode>{`.
For example, `}<inlineCode parentName="p">{`Collect Retasking Test 1`}</inlineCode>{` will become `}<inlineCode parentName="p">{`Collect Retasking Test 1 – CID: collectId`}</inlineCode>{`. If the `}<inlineCode parentName="p">{`repeatRequestDescription`}</inlineCode>{` is not specified, then the description of the new tasking request will be `}<inlineCode parentName="p">{`– CID: collectId`}</inlineCode>{`. This is to provide the user with an easy way to link between the original collection and the new tasking requests. `}</p>
    <p>{`The other available properties can be used to define when the data will be collected or to change the look angles to a user-specified range:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`retaskRequestName`}</inlineCode>{` and `}<inlineCode parentName="li">{`retaskRequestDescription`}</inlineCode>{` can be used to help characterize and describe the new tasking request `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowOpen`}</inlineCode>{` can optionally be set to a specific time in UTC you would like data collection to start or will automatically default to today, if not specified`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`windowClose`}</inlineCode>{` can optionally be set for a time longer than the `}<inlineCode parentName="li">{`collectionTier`}</inlineCode>{` indicates, which will extend the acquisition window for your request`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`collectionTier`}</inlineCode>{` indicates your preference for data to be collected within a particular timeframe and can be set to `}<inlineCode parentName="li">{`urgent`}</inlineCode>{`, `}<inlineCode parentName="li">{`priority`}</inlineCode>{`, `}<inlineCode parentName="li">{`standard`}</inlineCode>{`, or `}<inlineCode parentName="li">{`flexible`}</inlineCode>{`. If not specified, the new tasking request will default to `}<inlineCode parentName="li">{`standard`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`offNadirMin`}</inlineCode>{` will default to -5 degrees from the look angle of the original collection to provide consistent imaging geometries, if the property is not included in the request. You can change this to be any number between 5 and 50, although it must be smaller than the `}<inlineCode parentName="li">{`offNadirMax`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`offNadirMax`}</inlineCode>{` will default to +5 degrees from the look angle of the original collection to provide consistent imaging geometries, if the property is not included in the request. You can change this to be any number between 5 and 50, although it must be larger than the `}<inlineCode parentName="li">{`offNadirMin`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`archiveHoldback`}</inlineCode>{` sets the timeline for which the resulting collect is made available in the Capella archive and can be set to `}<inlineCode parentName="li">{`none`}</inlineCode>{`, `}<inlineCode parentName="li">{`30_day`}</inlineCode>{`, `}<inlineCode parentName="li">{`1_year`}</inlineCode>{`, and `}<inlineCode parentName="li">{`permanent`}</inlineCode>{`. If not specified, the `}<inlineCode parentName="li">{`archiveHoldback`}</inlineCode>{` will default to the value set by your organization's contract.`}</li>
    </ul>
    <p>{`The response will contain detailed information about the new single tasking request. `}</p>
    <h4 {...{
      "id": "geometrically-similar-collects"
    }}>{`Geometrically Similar Collects`}</h4>
    <p>{`All tasking requests created through the `}<inlineCode parentName="p">{`collects/{collectID}/retask`}</inlineCode>{` endpoint are given the `}<inlineCode parentName="p">{`productCategory`}</inlineCode>{` of `}<inlineCode parentName="p">{`custom`}</inlineCode>{`. This ensures the tasking request can be constructed to match the original collection as closely as possible. It also provides the user with the option to customize the look angles rather than leverage the default 10 degree (+/-5) look angle tolerance. `}</p>
    <p>{`For example, if the original collection had a look angle of 32 degrees, the collection created from the new tasking request could have a look angle between 27 and 37 degrees. If you set the `}<inlineCode parentName="p">{`offNadirMin`}</inlineCode>{` to 30 and do not specify an `}<inlineCode parentName="p">{`offNadirMax`}</inlineCode>{`, the resulting collection would have a look angle between 30 and 37. If you set the `}<inlineCode parentName="p">{`offNadirMin`}</inlineCode>{` to 5 and set the `}<inlineCode parentName="p">{`offNadirMax`}</inlineCode>{` to 30, the resulting collection would have a look angle between those two values even though the original collection had a look angle of 32 degrees.`}</p>
    <h3 {...{
      "id": "retasking-a-repeat-tasking-request"
    }}>{`Retasking a Repeat Tasking Request`}</h3>
    <p>{`If you have an existing repeat tasking request that you would like Capella to recollect for you, you can submit the existing `}<inlineCode parentName="p">{`repeatRequestId`}</inlineCode>{` for retasking by making a request to the POST `}<inlineCode parentName="p">{`repeat-request/{repeatRequestId}/retask`}</inlineCode>{` endpoint. This request will generate a new repeat tasking request with the point target from the original repeat tasking request. `}</p>
    <p>{`The request can be sent with an empty GeoJSON feature body or with a select number of properties to add identifying information to the request or change the Tasking Tier and collection time parameters of the request:`}</p>
    <pre><code parentName="pre" {...{}}>{`  POST https://api.capellaspace.com/repeat-request/{repeatRequestID}/retask
    {
        "retaskRequestName": "My first repeat retasking request",
        "retaskRequestDescription": "Repeat Retasking Test 1",
        "repeatStart": "2023-12-13T00:49:59.944Z",
        "repetitionInterval": 3,
        //"repeatEnd": "2023-02-13T00:49:59.944Z",
        //"repetitionCount": 5,
        "untilCanceled": true,
        "collectionTier": "flexible",
        "archiveHoldback": "30_day"
    }
`}</code></pre>
    <p>{`All the available properties are optional. If not specified, the values will default to those from the original repeat tasking request. The original `}<inlineCode parentName="p">{`repeatRequestId`}</inlineCode>{` will be appended to the `}<inlineCode parentName="p">{`retaskRequestDescription`}</inlineCode>{`, if specified, or will be appended to the `}<inlineCode parentName="p">{`repeatRequestDescription`}</inlineCode>{` from the original repeat request, if not specified. `}</p>
    <p>{`For example, the `}<inlineCode parentName="p">{`Repeat Retasking Test 1`}</inlineCode>{` specified above will become `}<inlineCode parentName="p">{`Repeat Retasking Test 1 – RRID: repeatRequestId`}</inlineCode>{`. If the original repeat request had a `}<inlineCode parentName="p">{`repeatRequestDescription`}</inlineCode>{` of `}<inlineCode parentName="p">{`Airplane monitoring`}</inlineCode>{` and a new description is not specified, the description of the new repeat request will become `}<inlineCode parentName="p">{`Airplane monitoring – RRID: repeatRequestId`}</inlineCode>{`. This is to provide the user with an easy way to link between the original and new repeat tasking requests. `}</p>
    <p>{`The other available parameters can be used to add identifying information to the new repeat tasking request, set the importance of when the data should be collected by changing the Tasking Tier, or modify the end trigger for the series:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`retaskRequestName`}</inlineCode>{` and `}<inlineCode parentName="li">{`retaskRequestDescription`}</inlineCode>{` can be used to help characterize and describe the new tasking request `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`repeatStart`}</inlineCode>{` can optionally be set to a specific time in UTC you would like data collection to start. If not specified, the `}<inlineCode parentName="li">{`repeatStart`}</inlineCode>{` will automatically default to the date and time of submission. `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`repetitionInterval`}</inlineCode>{` is the number of days between the start of derived requests. For example, a `}<inlineCode parentName="li">{`repetitionInterval`}</inlineCode>{` equal to 3 means requests will open 3 days after the opening previous request.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`repeatEnd`}</inlineCode>{` is one of three end triggers for a repeat request. If you provide a date, the repeat request will continue collecting imagery until the date is met.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`repetitionCount`}</inlineCode>{` is the second available end triggers for a repeat series. If you include this property, the total number of acquisitions for a repeat series will be set. Once this number is met with completed acquisitions, the series will end.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`untilCanceled`}</inlineCode>{` is the third available end triggers for a repeat series. If you include this property and set it to true, the new repeat request will continue until you send a `}<a parentName="li" {...{
          "href": "https://docs.capellaspace.com/constellation-tasking/cancel-task"
        }}>{`PATCH request to cancel the repeat series`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`collectionTier`}</inlineCode>{` can be optionally set indicate the importance of the tasking request for the scheduling system and can be set to either `}<inlineCode parentName="li">{`routine`}</inlineCode>{` or `}<inlineCode parentName="li">{`flexible`}</inlineCode>{`. If not specified, the `}<inlineCode parentName="li">{`collectionTier`}</inlineCode>{` will default to tier used in the original request.   `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`archiveHoldback`}</inlineCode>{` sets the timeline for which the resulting collect is made available in the Capella archive and can be set to `}<inlineCode parentName="li">{`none`}</inlineCode>{`, `}<inlineCode parentName="li">{`30_day`}</inlineCode>{`, `}<inlineCode parentName="li">{`1_year`}</inlineCode>{`, and `}<inlineCode parentName="li">{`permanent`}</inlineCode>{`. If not specified, the archiveHoldback will default to the value used in the original request.`}</li>
    </ul>
    <p>{`In the above request, `}<inlineCode parentName="p">{`untilCanceled`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`true`}</inlineCode>{` while neither the `}<inlineCode parentName="p">{`repeatEnd`}</inlineCode>{` nor the `}<inlineCode parentName="p">{`repetitionCount`}</inlineCode>{` are specified. Therefore, the new repeat request will continue until you send a `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/constellation-tasking/cancel-task"
      }}>{`PATCH request to cancel the repeat series`}</a>{`. (Remove `}<inlineCode parentName="p">{`//`}</inlineCode>{` from in front of one of the other two properties and either add `}<inlineCode parentName="p">{`//`}</inlineCode>{` before or remove the `}<inlineCode parentName="p">{`untilCanceled`}</inlineCode>{` parameter to change the repeat end trigger.) `}</p>
    <p>{`The response will contain detailed information about your new repeat tasking request, including the specific constraints used for imaging. `}</p>
    <h4 {...{
      "id": "geometrically-similar-collects-1"
    }}>{`Geometrically Similar Collects`}</h4>
    <p>{`All other properties, including the `}<inlineCode parentName="p">{`collectConstraints`}</inlineCode>{`, for the new repeat tasking request are inherited from the original repeat request. If the original repeat request included `}<inlineCode parentName="p">{`maintainSceneFraming: enabled`}</inlineCode>{` or a `}<inlineCode parentName="p">{`lookAngleTolerance`}</inlineCode>{` other than `}<inlineCode parentName="p">{`null`}</inlineCode>{`, those constraints will be applied to the new repeat request and the Capella system will collect a series of geometrically similar collects within the new series.  It will not create a new series that is geometrically similar with the original series. If you need to resubmit a tasking request to generate cohesive images, then you should use the Retask an image from the archive endpoint. `}</p>
    <h2 {...{
      "id": "designating-collection-time"
    }}>{`Designating Collection Time`}</h2>
    <p>{`You can designate your single and repeat tasking request should only be collected during specific times of the day by using the `}<inlineCode parentName="p">{`localTime`}</inlineCode>{` parameter in the `}<inlineCode parentName="p">{`collectConstraints`}</inlineCode>{` object of the tasking request.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`localTime`}</inlineCode>{` parameter accepts either pre-defined string values or custom array values, representing the seconds in the day during which the collect can be taken. This parameter is available for all imaging modes and product categories and can be used to reduce the acquisition window duration to be shorter than the minimum for your selected `}<inlineCode parentName="p">{`collectionTier`}</inlineCode>{`. `}</p>
    <h3 {...{
      "id": "pre-defined-values"
    }}>{`Pre-Defined Values`}</h3>
    <p>{`Three pre-defined collection times can be specified by submitting the string value as part of your `}<inlineCode parentName="p">{`collectConstraints`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`"collectConstraints": {
    "localTime": “night”
  }
`}</code></pre>
    <p>{`These times are:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`day`}</inlineCode>{`: between 6AM and 6PM (06:00 – 18:00) local time`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`night`}</inlineCode>{`: between 6PM and 6AM (18:00 – 6:00) local time`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`anytime`}</inlineCode>{`: the full 24-hour period of a day (0:00-24:00)`}</li>
    </ol>
    <p>{`Daylight Saving Time is considered when determining the collection time. The time zone is based on the center point of the tasking request area of interest (AOI). `}</p>
    <p>{`The default `}<inlineCode parentName="p">{`localTime`}</inlineCode>{` for repeat and single tasking requests is `}<inlineCode parentName="p">{`anytime`}</inlineCode>{`. This value will be returned in the response if you do not specify one of the other pre-defined values or a custom array. `}</p>
    <h3 {...{
      "id": "custom-values"
    }}>{`Custom Values`}</h3>
    <p>{`If you would like to provide a custom time for collection, you can specify an array using the same `}<inlineCode parentName="p">{`localTime`}</inlineCode>{` parameter. The parameter is structured to accepted seconds in the day where 0 is the first second of the day and 86400 is the last second in the day. This calculation can be done by accounting for 60 seconds per minute, 60 minutes per hour, for however many hours from 12AM (0:00) you would like. `}</p>
    <p>{`For example, if you want your tasking request to be collected between 4AM and 5PM (17:00), you would configure your request to include the following:`}</p>
    <pre><code parentName="pre" {...{}}>{`"collectConstraints": {
    "localTime": [14400.0, 61200.0]
  }
`}</code></pre>
    <p>{`Your custom array value will then be returned in the response. If you’re custom array aligns with the pre-defined times set by the `}<inlineCode parentName="p">{`day`}</inlineCode>{`, `}<inlineCode parentName="p">{`night`}</inlineCode>{`, or `}<inlineCode parentName="p">{`anytime`}</inlineCode>{` string values, then that value will be returned in the response instead. `}</p>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information on constellation tasking requests, see the Support site for `}<a parentName="p" {...{
        "href": "https://support.capellaspace.com/hc/en-us/categories/360002628311-Tasking"
      }}>{`Tasking`}</a>{` as well as the API reference for `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/api/tasking"
      }}>{`Tasking`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      